import React from 'react';
import Stock from './types/Stock';
import PriceDaily from './types/PriceDaily';

export interface AppContextInterface {
    setPriceDaily(stock: Stock, priceDaily: PriceDaily[]);
    getPriceDaily(stock: Stock) : PriceDaily[];
}

export const AppContext = React.createContext<AppContextInterface>({
    setPriceDaily(stock: Stock, priceDaily: PriceDaily[]) {
        // mock for now, let the user implement the details
    },
    getPriceDaily(stock: Stock) {
        // mock for now, let the user implement the details
        return [];
    }
});

interface IndexPageContextInterface {
    stocks: Stock[]
}

export const IndexPageContext = React.createContext<IndexPageContextInterface>({
    stocks: []
});