/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import App from './src/App';

export const onClientEntry = function (_, pluginParams) {
    if (process.env.NODE_ENV === 'production') {
        registerSentry();
    }
};

export const wrapRootElement = function ({ element }) {
    return <App>{element}</App>
}

function registerSentry() {
    require.ensure(['@sentry/browser'], function (require) {
        const Sentry = require('@sentry/browser');

        // Sentry: https://sentry.io/organizations/vrymel-omandam/issues/?project=5274430
        Sentry.init({dsn: "https://469a282ef1944ed9a98e50c93c12b2c3@o58707.ingest.sentry.io/5274430"});

        window.Sentry = Sentry;
    });
}
