import React, {useState} from 'react';

import {AppContext, AppContextInterface} from './contexts';
import Stock from "./types/Stock";
import PriceDaily from "./types/PriceDaily";


function App({children}) {
    const [priceDailyStore, setPriceDailyStore] = useState([]);

    // This is a very simple implementation of caching the price daily.
    // What you set is what you get here. The idea is to store the initial data and just use that.
    // Ideally the data stored here should be the latest.
    const contextValue: AppContextInterface = {
        getPriceDaily(stock: Stock) {
            if (!priceDailyStore[stock.ticker_symbol])
                return [];

            return priceDailyStore[stock.ticker_symbol];
        },
        setPriceDaily(stock: Stock, priceDaily: PriceDaily[]) {
            setPriceDailyStore({...priceDailyStore, [stock.ticker_symbol]: priceDaily});
        }
    };

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export default App;