// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-0-about-js": () => import("./../../../src/pages/0/about.js" /* webpackChunkName: "component---src-pages-0-about-js" */),
  "component---src-pages-0-chart-js": () => import("./../../../src/pages/0/chart.js" /* webpackChunkName: "component---src-pages-0-chart-js" */),
  "component---src-pages-0-download-database-dump-js": () => import("./../../../src/pages/0/download-database-dump.js" /* webpackChunkName: "component---src-pages-0-download-database-dump-js" */),
  "component---src-pages-0-most-active-js": () => import("./../../../src/pages/0/most-active.js" /* webpackChunkName: "component---src-pages-0-most-active-js" */),
  "component---src-pages-0-screener-tsx": () => import("./../../../src/pages/0/screener.tsx" /* webpackChunkName: "component---src-pages-0-screener-tsx" */),
  "component---src-pages-0-stock-js": () => import("./../../../src/pages/0/stock.js" /* webpackChunkName: "component---src-pages-0-stock-js" */),
  "component---src-pages-0-stocks-tsx": () => import("./../../../src/pages/0/stocks.tsx" /* webpackChunkName: "component---src-pages-0-stocks-tsx" */),
  "component---src-pages-0-watchboard-tsx": () => import("./../../../src/pages/0/watchboard.tsx" /* webpackChunkName: "component---src-pages-0-watchboard-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

